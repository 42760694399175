import React from 'react';

import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql, } from "gatsby"

import Facebook from "../images/facebook.svg";
import Instagram from "../images/instagram.svg";
import Whatsapp from "../images/whatsapp.svg";
import Email from "../images/email.svg";

const Contactme = () => {

  const { allContentfulContactmePage } = useStaticQuery(graphql`
    query {
      allContentfulContactmePage(filter: {background: {description: {ne: "default"}}}) {
        edges {
          node {
            background {
              fluid {
                ...GatsbyContentfulFluid
              }
            }
            contactEmail
            pageName
            contactName
            contactPhone
            instagramLink {
              instagramLink
            }
          }
        }
      }
    }
`);

    const data = allContentfulContactmePage.edges[0];

    const matcher = (data) => {
      const match = data.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
      }
    }

    return (
        <Layout>
            <SEO title="Contactme" />
            <div id="contactContentElem">
              <div id="contact_content">
                  <div id="info">
                    <h1>{ data.node.pageName }</h1>
                    <strong>Yo soy:</strong> <b/>
                    { 
                      data.node.contactName != null &&
                      data.node.contactName.join(",")
                    }
                    <p />
                    <Whatsapp style={{width: '2em', height: '2em'}}/>
                    { 
                      data.node.contactPhone != null &&
                      data.node.contactPhone.map((elem)=>matcher(elem)).join(",")
                    }
                    <Email style={{width: '2em', height: '2em', marginLeft: '1%'}}/>
                    { data.node.contactEmail }
                    <p />
                    <div id="contactForm" style={{display: 'flex', flexDirection: 'column'}}>
                      <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
                        <div><label id="namelbl" htmlFor="name">Nombre:</label><input name="name" className="contact_field"/></div>
                        <div><label id="emaillbl" htmlFor="email">Correo:</label><input name="email" className="contact_field"/></div>
                        <div><label id="commentlbl" htmlFor="comment">Comentario:</label><textarea name="comment" rows="3" className="contact_field"/></div>
                        <button id="sendButton">Enviar</button>
                      </form>
                    </div>
                  </div>
              </div>
              <div id="contact_links">
                  <h3>También consígueme en: </h3>
                  <a href="https://www.facebook.com/Chick-Meal-Prep-105063347608346/">
                      <Facebook style={{width: '3em', height: '3em'}}/>
                  </a>
                  <a href="https://www.instagram.com/chickmealprep/">
                      <Instagram style={{width: '3em', height: '3em'}}/>
                  </a>
              </div>
            </div>
        </Layout>
    )
}

export default Contactme;